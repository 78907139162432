import WelcomeSecDataEN from "../../../languages/EN/WelcomeSecEN.json";
import WelcomeSecDataAR from "../../../languages/AR/WelcomeSecAR.json";

const SET_WELCOMEDATA = 'SET_WELCOMEDATA';

const welcome = () => {
    const lang = localStorage.getItem('lang');
    if (lang === 'EN' || lang == null) {
        return WelcomeSecDataEN;
    } else {
        return WelcomeSecDataAR;
    }
}

const state = {
    welcome: welcome()
}

const mutations = {}

const actions = {}

const getters = {
    getWelcomeSecData: (state) => {
        return state.welcome
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}