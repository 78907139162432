import GeneralDetailsDataEN from "../../../languages/EN/GeneralDetailsEN.json";
import GeneralDetailsDataAR from "../../../languages/AR/GeneralDetailsAR.json";
import GeneralDetailsService from "../../services/GeneralDetailsService";

const GET_PRIMARY_PASSENGER = "GET_PRIMARY_PASSENGER";
const GET_SECONDARY_PASSENGER = "GET_SECONDARY_PASSENGER";
const GET_SAVED_PRIMARY_PASSENGER = "GET_SAVED_PRIMARY_PASSENGER";
const CEHCK_EMAILANDMOBILE = "CEHCK_EMAILANDMOBILE";
const GET_USER_PROFILE_DETAILS = "GET_USER_PROFILE_DETAILS";
const SET_PRIMARY_DETAILS = "SET_PRIMARY_DETAILS";
const SET_UPDATE_PRIMARY_DETAILS = "SET_UPDATE_PRIMARY_DETAILS";
const SET_SECONDARY_DETAILS = "SET_SECONDARY_DETAILS";
const SET_UPDATE_SECONDARY_DETAILS = "SET_UPDATE_SECONDARY_DETAILS";
const DELETE_SECONDARY_PASSENGER = "DELETE_SECONDARY_PASSENGER";
const SET_PRIMARY_PASSENGER_EDIT = "SET_PRIMARY_PASSENGER_EDIT";

const delay = () => new Promise((res) => setTimeout(res, 1000));

const generalDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return GeneralDetailsDataEN;
  } else if (lang === "AR" || lang == null) {
    return GeneralDetailsDataAR;
  }
};

const state = {
  generalDetailsLabels: generalDetails(),
  primaryPassenger: [],
  secondaryPassenger: [],
  primaryPassengerSavedData: {
    Profile: "",
    passangerFirstName: "",
    passangerLastName: "",
    passangerEmail: "",
    passangerContactNumber: "",
    passangerAddress: "",
    passangerPassportNumber: "",
    passangerFlightNumber: "",
    passangeDepDate: "",
    passangeDepTime: "",
    passangerPassportImage: "",
  },
  checkEmailAndMobile: "",
  userProfileDetails: {},
  isPrimaryEdit: false,
};

const actions = {
  async getAllPrimaryPassenger({ commit }) {
    GeneralDetailsService.getPrimaryPassenger().then((data) => {
      commit(GET_PRIMARY_PASSENGER, data);
    });
  },
  async getAllSecondaryPassenger({ commit }) {
    GeneralDetailsService.getSecondaryPassenger().then((data) => {
      const secondaryPassengerData = data;
      commit(GET_SECONDARY_PASSENGER, secondaryPassengerData);
    });
  },

  async CheckValidationOfEmailAndMobile({ commit }, userData) {
    GeneralDetailsService.checkEmailAndMobileConfirmation(userData).then(
      (data) => {
        if (data.status === 200) {
          const checkEmailAndMobile = data.data;
          commit(CEHCK_EMAILANDMOBILE, checkEmailAndMobile);
        }
      }
    );
  },

  getUserProfileDetails({ commit }) {
    GeneralDetailsService.getUserProfileDetails().then((data) => {
      commit(GET_USER_PROFILE_DETAILS, data);
    });
  },
  setGeneralDetails({ commit }, primaryPassenger) {
    commit(SET_PRIMARY_DETAILS, primaryPassenger);
  },
  setUpdateGeneralDetails({ commit }, primaryPassenger) {
    commit(SET_UPDATE_PRIMARY_DETAILS, primaryPassenger);
  },

  setSecondaryDetails({ commit }, secondaryPassenger) {
    commit(SET_SECONDARY_DETAILS, secondaryPassenger);
  },

  setUpdateSecondaryDetails({ commit }, secondaryPassenger) {
    commit(SET_UPDATE_SECONDARY_DETAILS, secondaryPassenger);
  },

  deleteSecondaryPassenger({ commit }, index) {
    commit(DELETE_SECONDARY_PASSENGER, index);
  },
  setPrimaryEdit({ commit }, isPrimaryEdit) {
    commit(SET_PRIMARY_PASSENGER_EDIT, isPrimaryEdit);
  },
};

const mutations = {
  [GET_USER_PROFILE_DETAILS](state, userProfileDetails) {
    state.userProfileDetails = userProfileDetails;
  },

  [GET_PRIMARY_PASSENGER](state, primaryPassenger) {
    state.primaryPassenger = primaryPassenger;
  },
  [GET_SECONDARY_PASSENGER](state, secondaryPassenger) {
    state.secondaryPassenger = secondaryPassenger;
  },
  [GET_SAVED_PRIMARY_PASSENGER](state, saved) {
    state.primaryPassengerSavedData = saved;
  },
  [CEHCK_EMAILANDMOBILE](state, checkEmailAndMobile) {
    state.checkEmailAndMobile = checkEmailAndMobile;
  },
  [SET_PRIMARY_DETAILS](state, primaryPassenger) {
    state.primaryPassenger.push(primaryPassenger);
  },
  [SET_UPDATE_PRIMARY_DETAILS](state, primaryPassenger) {
    //clear all state.primaryPassenger array elements
    state.primaryPassenger = [];
    state.primaryPassenger.push(primaryPassenger);
  },
  [SET_SECONDARY_DETAILS](state, secondaryPassenger) {
    state.secondaryPassenger.push(secondaryPassenger);
  },

  [SET_UPDATE_SECONDARY_DETAILS](state, secondaryPassenger) {
    //clear all state.primaryPassenger array elements
    state.secondaryPassenger.push(secondaryPassenger);
  },

  [DELETE_SECONDARY_PASSENGER](state, index) {
    state.secondaryPassenger.splice(index, 1);
  },
  [SET_PRIMARY_PASSENGER_EDIT](state, isPrimaryEdit) {
    state.isPrimaryEdit = isPrimaryEdit;
  },
};

const getters = {
  getGeneralDetailsLabel: (state) => {
    return state.generalDetailsLabels;
  },
  getPrimaryPassengerDetails(state) {
    return state.primaryPassenger;
  },
  getSecondaryPassengerDetails(state) {
    return state.secondaryPassenger;
  },
  getSavedPrimaryPassengerDetails(state) {
    return state.state.primaryPassenger[0];
  },
  getResponseOfCheckEmailAndMobile(state) {
    return state.checkEmailAndMobile;
  },
  userProfile: (state) => {
    return state.userProfileDetails;
  },
  getPrimaryEdit: (state) => {
    return state.isPrimaryEdit;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
