import PaymentOptionEN from "../../../languages/EN/PaymentOptionEN.json";
import paymentOptionAR from "../../../languages/AR/PaymentOptionAR.json";

const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";
const GET_BOOKING_ID = "GET_BOOKING_ID";
const SET_DISPLAY_SUMMARY = "SET_DISPLAY_SUMMARY";

const paymentOptionDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return PaymentOptionEN;
  } else if (lang === "AR" || lang == null) {
    return paymentOptionAR;
  }
};

const state = {
  paymentOption: paymentOptionDetails(),
  paymentType: "",
  bookingId: 0,
  displaySummary: false,
};

const mutations = {
  [SET_PAYMENT_TYPE](state, paymentType) {
    state.paymentType = paymentType;
  },
  [GET_BOOKING_ID](state, bookingId) {
    state.bookingId = bookingId;
  },
  [SET_DISPLAY_SUMMARY](state, displaySummary) {
    state.displaySummary = displaySummary;
  },
};

const actions = {
  setPaymentType({ commit }, paymentType) {
    commit(SET_PAYMENT_TYPE, paymentType);
  },
  setBookingId({ commit }, bookingId) {
    commit(GET_BOOKING_ID, bookingId);
  },

  setDiaplaySummary({ commit }, displaySummary) {
    commit(SET_DISPLAY_SUMMARY, displaySummary);
  },
};

const getters = {
  getPaymentOptionDetails: (state) => {
    return state.paymentOption;
  },

  getPaymentType: (state) => {
    return state.paymentType;
  },

  getBookingId: (state) => {
    return state.bookingId;
  },
  displaySummary: (state) => {
    return state.displaySummary;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
