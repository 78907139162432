import loginEN from "../../../languages/EN/LoginEN.json";
import loginAR from "../../../languages/AR/LoginAR.json";

const getLoginDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return loginEN;
  } else if (lang === "AR" || lang == null) {
    return loginAR;
  }
};

const state = {
  loginOption: getLoginDetails(),
};

const mutations = {};

const actions = {};
const getters = {
  getLoginDetails: (state) => {
    return state.loginOption;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
