import VerificationEN from "../../../languages/EN/VerificationEN.json";

const SET_VERIFICATION = 'SET_VERIFICATION';

const verificationDetails = () => {
    const lang = 'en';
    if (lang == 'en') {
        return VerificationEN;
    }
}

const state = {
    verification: verificationDetails()
}

const mutations = {}

const actions = {}

const getters = {
    getVerification: (state) => {
        return state.verification
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}