import PickupLocationEN from "../../../languages/EN/PickupLocationEN.json";
import PickupLocationAR from "../../../languages/AR/PickupLocationAR.json";

const SET_PICKUP_LOCATION = "SET_PICKUP_LOCATION";

const pickupLocationDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return PickupLocationEN;
  } else if (lang === "AR" || lang == null) {
    return PickupLocationAR;
  }
};

const state = {
  pickupLocation: pickupLocationDetails(),
  locations: {},
};

const mutations = {
  [SET_PICKUP_LOCATION](state, locations) {
    state.locations = locations;
  },
};

const actions = {
  setPickupLocations({ commit }, pickUpLocations) {
    commit(SET_PICKUP_LOCATION, pickUpLocations);
  },
};

const getters = {
  getPickupLocationDetails: (state) => {
    return state.pickupLocation;
  },

  pickupLocation: (state) => {
    return state.locations;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
