import forgotEN from "../../../languages/EN/ForgotEN.json";
import forgotAR from "../../../languages/AR/ForgotAR.json";

const getForgotDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return forgotEN;
  } else if (lang === "AR" || lang == null) {
    return forgotAR;
  }
};

const state = {
  forgotOption: getForgotDetails(),
};

const mutations = {};

const actions = {};
const getters = {
  getForgotDetails: (state) => {
    return state.forgotOption;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
