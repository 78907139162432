import dropOffLocationEN from "../../../languages/EN/DropOffLocationEN.json";
import dropOffLocationAR from "../../../languages/AR/DropOffLocationAR.json";
import DropOffLocationService from "../../services/DropOffLocationService";

const GET_DROP_OFF_LOCATIONS = "GET_DROP_OFF_LOCATIONS";
const SET_DROP_OFF_LOCATIONS_DETAILS = "SET_DROP_OFF_LOCATIONS_DETAILS";

const dropOffLocationDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return dropOffLocationEN;
  } else if (lang === "AR" || lang == null) {
    return dropOffLocationAR;
  }
};

const state = {
  dropOffLocationLabels: dropOffLocationDetails(),
  dropOffLocation: [],
  dropOffLocationDetails: {},
};

const actions = {
  async getAllDropOffLocationDetails({ commit }) {
    DropOffLocationService.getDropOffLocations().then((data) => {
      const dropOffLocation = data;
      commit(GET_DROP_OFF_LOCATIONS, dropOffLocation);
    });
  },

  setDropOFFLocationDetails({ commit }, dropOffLocation) {
    commit(SET_DROP_OFF_LOCATIONS_DETAILS, dropOffLocation);
  },
};

const mutations = {
  [GET_DROP_OFF_LOCATIONS](state, dropOffLocation) {
    state.dropOffLocation = dropOffLocation;
  },
  [SET_DROP_OFF_LOCATIONS_DETAILS](state, dropOffLocation) {
    state.dropOffLocationDetails = dropOffLocation;
  },
};

const getters = {
  getDropOffLocationDetails: (state) => {
    return state.dropOffLocationLabels;
  },
  getDropOffLocationInputDetails(state) {
    return state.dropOffLocationDetails;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
