import profileEN from "../../../languages/EN/ProfileEN.json";
import profileAR from "../../../languages/AR/ProfileAR.json";

const getProfileDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return profileEN;
  } else if (lang === "AR" || lang == null) {
    return profileAR;
  }
};

const state = {
  profileOption: getProfileDetails(),
};

const mutations = {};

const actions = {};
const getters = {
  getProfileDetails: (state) => {
    return state.profileOption;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
