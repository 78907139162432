import ConfirmationEN from "../../../languages/EN/ConfirmationEN.json";
import ConfirmationAR from "../../../languages/AR/ConfirmationAR.json";

const SET_CONFIRMATION = "SET_CONFIRMATION";
const SET_CONFIRM_USER_DETAILS = "SET_CONFIRM_USER_DETAILS";

const confirmationDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return ConfirmationEN;
  } else if (lang === "AR" || lang == null) {
    return ConfirmationAR;
  }
};

const state = {
  confirmation: confirmationDetails(),
  confirmUserDetails: {},
};

const mutations = {
  [SET_CONFIRM_USER_DETAILS](state, confirmUserDetails) {
    state.confirmUserDetails = confirmUserDetails;
  },
};

const actions = {
  setConfirmUserDetails({ commit }, confirmUserDetails) {
    commit(SET_CONFIRM_USER_DETAILS, confirmUserDetails);
  },
};

const getters = {
  getConfirmation: (state) => {
    return state.confirmation;
  },
  getConfirmUserDetails: (state) => {
    return state.confirmUserDetails;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
