<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.swal2-popup {
  border-radius: 20px !important;
  width: 20em !important;
}

.swal2-confirm {
  background-color: #006203 !important;
  border-radius: 20px !important;
}

.swal2-cancel {
  background-color: #db0e03 !important;
  border-radius: 0.25em !important;
}

.swal2-title {
  font-size: 20px !important;
}

.swal2-html-container {
  font-size: 15px !important;
}

.swal2-styled {
  padding: 3px 30px !important;
}

.swal2-styled.swal2-confirm {
  border-radius: 0.25em !important;
}

#app {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  scroll-behavior: smooth;
  /*text-align: right;*/
}

.modal-backdrop {
  z-index: -1;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 12px;
  height: 5px;
  background: white;
  padding-right: 10px;
  border-radius: 5px;
}

.clock-timepicker {
  display: block !important;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(0, 98, 3) / 25% !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #006203;
}

@media all and (max-width: 1024px) {
  .charactImgAR {
    left: -50px !important;
    height: 150px !important;
    width: 150px !important;
  }
}

@media all and (max-width: 768px) {
  .charactImgAR {
    left: -50px !important;
  }
}
</style>
