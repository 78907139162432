import { createStore } from "vuex";
import welcome from "./modules/Welcome";
import generalDetails from "./modules/GeneralDetails";
import pickupLocation from "./modules/PickupLocation";
import dropOffLocation from "./modules/Drop-offLocation";
import luggageDetails from "./modules/LuggageDetails";
import luggagePhotos from "./modules/LuggagePhotos";
import verification from "./modules/Verification";
import confirmation from "./modules/Confirmation";
import emailVerification from "./modules/EmailVerification";
import success from "./modules/Success";
import paymentOption from "./modules/PaymentOption";
import booking from "./modules/Booking";
import travelHistory from "./modules/TravelHistory";
import profile from "./modules/Profile";
import home from "./modules/Home";
import login from "./modules/Login";
import forgot from "./modules/Forgot";

export default createStore({
  state: {
    currentUser: JSON.parse(sessionStorage.getItem("currentUser")),
  },
  getters: {
    currentUser: (state) => {
      return state.currentUser;
    },
  },
  mutations: {
    UpdateUserInMutation(state, user) {
      state.currentUser = user;
      sessionStorage.setItem("currentUser", user);
      sessionStorage.setItem("lang", "EN");
    },
    clearUserInMutation(state) {
      state.currentUser = null;
      sessionStorage.removeItem("currentUser");
    },
  },
  actions: {
    updateUser(state, user) {
      state.commit("UpdateUserInMutation", user);
    },
    clearUser(state) {
      state.commit(" clearUserInMutation");
    },
  },
  modules: {
    welcome,
    generalDetails,
    pickupLocation,
    dropOffLocation,
    luggageDetails,
    luggagePhotos,
    verification,
    confirmation,
    emailVerification,
    success,
    paymentOption,
    booking,
    travelHistory,
    profile,
    home,
    login,
    forgot,
  },
});
