import SuccessEN from "../../../languages/EN/SuccessEN.json";
import successAR from "../../../languages/AR/SuccessAR.json";

const SET_SUCCESS = "SET_SUCCESS";

const successDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return SuccessEN;
  } else if (lang === "AR" || lang == null) {
    return successAR;
  }
};

const state = {
  success: successDetails(),
  confirmationDetails: [],
};

const mutations = {
  [SET_SUCCESS](state, savedDetails) {
    state.confirmationDetails = savedDetails;
  },
};

const actions = {
  setSavedDetailsToStore({ commit }, savedDetails) {
    commit(SET_SUCCESS, savedDetails);
  },
};

const getters = {
  getSuccess: (state) => {
    return state.success;
  },

  getSavedDetails: (state) => {
    return state.confirmationDetails;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
