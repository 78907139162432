import dashboardEN from "../../../languages/EN/DashboardEN.json";
import dashboardAR from "../../../languages/AR/DashboardAR.json";
import BookingService from "../../services/BookingService";

const GETREASONLIST = "GETREASONLIST";
const GETBOOKINGHISTORY = "GETBOOKINGHISTORY";

const dashboardDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return dashboardEN;
  } else if (lang === "AR" || lang == null) {
    return dashboardAR;
  }
};

let state = {
  reasonDetails: [],
  bookingHistory: [],
  dashboard: dashboardDetails(),
};

const actions = {
  setReasonList({ commit }) {
    BookingService.getReasonList().then((response) => {
      const reasonList = response.data;
      commit(GETREASONLIST, reasonList);
    });
  },
  setBookingHistory({ commit }) {
    BookingService.getBookingHistory().then((response) => {
      const bookingHistory = response.data;

      commit(GETBOOKINGHISTORY, bookingHistory);
    });
  },
};

const mutations = {
  [GETREASONLIST](state, reasonList) {
    state.reasonDetails = reasonList;
  },
  [GETBOOKINGHISTORY](state, bookingHistory) {
    state.bookingHistory = bookingHistory;
  },
};

const getters = {
  dashboardDetails: (state) => {
    return state.dashboard;
  },
  reasonsList(state) {
    return state.reasonDetails;
  },
  getBookingHistory(state) {
    return state.bookingHistory;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
