import luggageDetailsEN from "../../../languages/EN/LuggageDetailsEN.json";
import luggageDetailsAR from "../../../languages/AR/LuggageDetailsAR.json";

const SET_LUGGAGE_DETAILS = "SET_LUGGAGE_DETAILS";
const SET_NEW_LUGGAGE_DETAILS = "SET_NEW_LUGGAGE_DETAILS";
const SET_ADDITIONAL_SERVICES = "SET_ADDITIONAL_SERVICES";

const luggageDetailsDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return luggageDetailsEN;
  } else if (lang === "AR" || lang == null) {
    return luggageDetailsAR;
  }
};

const state = {
  luggageDetails: luggageDetailsDetails(),
  luggageInputDetails: [],
  additionalList: [],
};

const mutations = {
  [SET_LUGGAGE_DETAILS](state, luggageDetails) {
    state.luggageInputDetails = luggageDetails;
  },
  [SET_NEW_LUGGAGE_DETAILS](state, luggageDetails) {
    //get each luggage details in luggageInputDetails
    luggageDetails.forEach((lg) => {
      state.luggageInputDetails.push(lg);
    });
  },
  [SET_ADDITIONAL_SERVICES](state, additionalList) {
    state.additionalList = additionalList;
  },
};

const actions = {
  setLuggageDetails({ commit }, luggageDetails) {
    commit(SET_LUGGAGE_DETAILS, luggageDetails);
  },
  setNewLuggageDetails({ commit }, luggageDetails) {
    commit(SET_NEW_LUGGAGE_DETAILS, luggageDetails);
  },
  setAdditionalServices({ commit }, additionalList) {
    commit(SET_ADDITIONAL_SERVICES, additionalList);
  },
};

const getters = {
  getluggageDetails: (state) => {
    return state.luggageDetails;
  },
  getluggageInputDetails: (state) => {
    return state.luggageInputDetails;
  },
  getAdditionalServices: (state) => {
    return state.additionalList;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
