import EmailVerificationEN from "../../../languages/EN/EmailVerificationEN.json";
import emailVerificationAR from "../../../languages/AR/EmailVerificationAR.json";

const CHECK_EMAIL_VERIFICATION = "CHECK_EMAIL_VERIFICATION";

const emailVerificationDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return EmailVerificationEN;
  } else if (lang === "AR" || lang == null) {
    return emailVerificationAR;
  }
};

const state = {
  emailVerification: emailVerificationDetails(),
  emailVerificationStatus: false,
};

const mutations = {
  [CHECK_EMAIL_VERIFICATION](state, emailVerification) {
    state.emailVerificationStatus = emailVerification;
  },
};

const actions = {
  checkEmailVerification({ commit }, emailVerification) {
    commit(CHECK_EMAIL_VERIFICATION, emailVerification);
  },
};

const getters = {
  getEmailVerification: (state) => {
    return state.emailVerification;
  },

  getEmailVerificationStatus: (state) => {
    return state.emailVerificationStatus;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
