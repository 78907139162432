import homeEN from "../../../languages/EN/HomeEN.json";
import homeAR from "../../../languages/AR/HomeAR.json";

const getHomeDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return homeEN;
  } else if (lang === "AR" || lang == null) {
    return homeAR;
  }
};

const state = {
  homeOption: getHomeDetails(),
};

const mutations = {};

const actions = {};
const getters = {
  getHomeDetails: (state) => {
    return state.homeOption;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
