import luggagePhotosEN from "../../../languages/EN/LuggagePhotosEN.json";
import luggagePhotosAR from "../../../languages/AR/LuggagePhotosAR.json";

const SET_LUGGAGE_IMAGE_DETAILS = "SET_LUGGAGE_IMAGE_DETAILS";
const SET_VERIFY_LUGGAGE_IMAGE = "SET_VERIFY_LUGGAGE_IMAGE";

const luggagePhotosDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return luggagePhotosEN;
  } else if (lang === "AR" || lang == null) {
    return luggagePhotosAR;
  }
};

const state = {
  luggagePhotos: luggagePhotosDetails(),
  luggageImageDetails: [],
  verifyLuggageImage: false,
};

const mutations = {
  [SET_LUGGAGE_IMAGE_DETAILS](state, luggageImageDetails) {
    state.luggageImageDetails = luggageImageDetails;
  },
  [SET_VERIFY_LUGGAGE_IMAGE](state, verifyLuggageImage) {
    state.verifyLuggageImage = verifyLuggageImage;
  },
};

const actions = {
  setLuggageImageDetails({ commit }, luggageImageDetails) {
    commit(SET_LUGGAGE_IMAGE_DETAILS, luggageImageDetails);
  },
  setVerifyLuggageImage({ commit }, verifyLuggageImage) {
    commit(SET_VERIFY_LUGGAGE_IMAGE, verifyLuggageImage);
  },
};

const getters = {
  getluggagePhotos: (state) => {
    return state.luggagePhotos;
  },
  getLuggageImageDetails: (state) => {
    return state.luggageImageDetails;
  },
  getVerifyLuggageImage: (state) => {
    return state.verifyLuggageImage;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
