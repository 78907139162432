import travelHistoryEN from "../../../languages/EN/TravelHistoryEN.json";
import travelHistoryAR from "../../../languages/AR/TravelHistoryAR.json";

const getTravelHistoryDetails = () => {
  const lang = localStorage.getItem("lang");
  if (lang === "EN" || lang == null) {
    return travelHistoryEN;
  } else if (lang === "AR" || lang == null) {
    return travelHistoryAR;
  }
};

const state = {
  travelHistoryOption: getTravelHistoryDetails(),
};

const mutations = {};

const actions = {};
const getters = {
  getTravelHistoryDetails: (state) => {
    return state.travelHistoryOption;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
