import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/success/:bookinID",
    name: "Successful",
    component: () => import("@/views/Successful.vue"),
  },
  {
    path: "/invoice/:bookinID",
    name: "Invoice",
    component: () => import("@/views/Invoice.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () => import("@/views/UserProfile.vue"),
  },
  {
    path: "/terms",
    name: "TermsAndCondition",
    component: () => import("@/views/TermsAndCondition.vue"),
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/reason",
    name: "CancelBookingReason",
    component: () =>
      import("@/common/components/invoice/CancelBookingReason.vue"),
  },
  {
    path: "/travel",
    name: "TravelDetails",
    component: () => import("@/views/TravelDetails.vue"),
  },
  {
    path: "/test",
    name: "LuggageDetails",
    component: () => import("@/views/test.vue"),
  },
  {
    path: "/reset/:email/:otp",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.VUE_APP_BASE_URL,
  routes,
  linkActiveClass: "activeBorder",
  linkExactActiveClass: "activeBorder",
});

export default router;
